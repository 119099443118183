import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, UrlSegment} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.scss']
})
export class LegalComponent implements OnInit {

  url: Observable<UrlSegment[]>;

  constructor(activatedRoute: ActivatedRoute) {
    this.url = activatedRoute.url;
  }

  ngOnInit(): void {
  }

  show(path: string): Observable<boolean> {
    return this.url.pipe(
      map(url => url[0].path !== path)
    );
  }
}
