import { Component, OnInit } from '@angular/core';
import {faLinkedin} from '@fortawesome/free-brands-svg-icons/faLinkedin';
import {faGithubSquare} from '@fortawesome/free-brands-svg-icons/faGithubSquare';
import {faInstagramSquare} from '@fortawesome/free-brands-svg-icons/faInstagramSquare';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  faLinkedin = faLinkedin;
  faGithub = faGithubSquare;
  faInstagram = faInstagramSquare;

  constructor() { }

  ngOnInit(): void {
  }

}
