<div class="page-container">
  <div>
    <h1>Willkommen @ <span class="highlighted">holzenkamp.me</span></h1>
  </div>

  <div class="links">
    <a href="https://www.linkedin.com/in/holzenkamp">
      <fa-icon [icon]="faLinkedin" size="2x"></fa-icon>
    </a>
    <a href="https://github.com/yannickholzenkamp">
      <fa-icon [icon]="faGithub" size="2x"></fa-icon>
    </a>
    <a href="https://www.instagram.com/yannickholzenkamp">
      <fa-icon [icon]="faInstagram" size="2x"></fa-icon>
    </a>
  </div>

  <div class="nav">
    <a [routerLink]="'impressum'">Impressum</a>
    <a [routerLink]="'datenschutz'">Datenschutz</a>
  </div>
</div>
