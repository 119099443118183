import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-cookie',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.scss']
})
export class CookieComponent {

  @Output()
  hideCookieBanner = new EventEmitter<void>();

}
