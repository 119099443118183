import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LandingPageComponent} from './landing-page/landing-page.component';
import {ImprintPageComponent} from './imprint-page/imprint-page.component';
import {DataPrivacyPageComponent} from './data-privacy-page/data-privacy-page.component';

const routes: Routes = [
  {path: 'impressum', component: ImprintPageComponent},
  {path: 'datenschutz', component: DataPrivacyPageComponent},
  {path: '', pathMatch: 'full', component: LandingPageComponent},
  {path: '**', redirectTo: '/'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
