<div class="wrapper">

  <div class="links">
    <a [routerLink]="'/'">Startseite</a>
    <a [routerLink]="'/impressum'" *ngIf="show('impressum') | async">Impressum</a>
    <a [routerLink]="'/datenschutz'" *ngIf="show('datenschutz') | async">Datenschutz</a>
  </div>

  <div class="text">
    <ng-content></ng-content>
  </div>

  <div class="links">
    <a [routerLink]="'/'">Startseite</a>
    <a [routerLink]="'/impressum'" *ngIf="show('impressum') | async">Impressum</a>
    <a [routerLink]="'/datenschutz'" *ngIf="show('datenschutz') | async">Datenschutz</a>
  </div>

</div>
