<app-legal>
  <h2>Datenschutzerkl&auml;rung</h2>
  <p>Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der
    EU-Datenschutzgrundverordnung (DSGVO), ist:</p><br/>
  <p>Yannick Holzenkamp<br/>Meret Oppenheim-Platz 1<br/>4053 Basel</p>
  <p>
    Telefon: 079 801 28 34<br/>E-Mail: contact@holzenkamp.me<br/>WebSite: https://holzenkamp.me/ </p>
  <p>&nbsp;</p>
  <h2>
    Allgemeiner Hinweis</h2>
  <p>Gest&uuml;tzt auf Artikel 13 der schweizerischen Bundesverfassung und den
    datenschutzrechtlichen Bestimmungen des Bundes (Datenschutzgesetz, DSG<!--DSG--> ) hat jede Person Anspruch auf
    Schutz
    ihrer Privatsph&auml;re sowie auf Schutz vor Missbrauch ihrer pers&ouml;nlichen Daten. Die Betreiber dieser Seiten
    nehmen den Schutz Ihrer pers&ouml;nlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich
    und
    entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerkl&auml;rung.</p>
  <p>In Zusammenarbeit
    mit unseren Hosting-Providern bem&uuml;hen wir uns, die Datenbanken so gut wie m&ouml;glich vor fremden Zugriffen,
    Verlusten, Missbrauch oder vor F&auml;lschung zu sch&uuml;tzen.</p>
  <p>Wir weisen darauf hin, dass die Daten&uuml;bertragung
    im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitsl&uuml;cken aufweisen kann. Ein l&uuml;ckenloser
    Schutz
    der Daten vor dem Zugriff durch Dritte ist nicht m&ouml;glich.</p>
  <p>Durch die Nutzung dieser Website erkl&auml;ren
    Sie sich mit der Erhebung, Verarbeitung und Nutzung von Daten gem&auml;ss der nachfolgenden Beschreibung
    einverstanden. Diese Website kann grunds&auml;tzlich ohne Registrierung besucht werden. Dabei werden Daten wie
    beispielsweise aufgerufene Seiten bzw. Namen der abgerufenen Datei, Datum und Uhrzeit zu statistischen Zwecken auf
    dem
    Server gespeichert, ohne dass diese Daten unmittelbar auf Ihre Person bezogen werden. Personenbezogene Daten,
    insbesondere Name, Adresse oder E-Mail-Adresse werden soweit m&ouml;glich auf freiwilliger Basis erhoben. Ohne
    Ihre
    Einwilligung erfolgt keine Weitergabe der Daten an Dritte.</p>
  <p>&nbsp;</p>
  <h2>Datenschutzerkl&auml;rung f&uuml;r
    Cookies</h2>
  <p>Diese Website verwendet Cookies. Das sind kleine Textdateien, die es m&ouml;glich machen, auf dem
    Endger&auml;t des Nutzers spezifische, auf den Nutzer bezogene Informationen zu speichern, w&auml;hrend er die
    Website
    nutzt. Cookies erm&ouml;glichen es, insbesondere Nutzungsh&auml;ufigkeit und Nutzeranzahl der Seiten zu ermitteln,
    Verhaltensweisen der Seitennutzung zu analysieren, aber auch unser Angebot kundenfreundlicher zu gestalten.
    <!--Allg.Cookies-->Cookies bleiben &uuml;ber das Ende einer Browser-Sitzung gespeichert und k&ouml;nnen bei einem
    erneuten Seitenbesuch wieder aufgerufen werden. Wenn Sie das nicht w&uuml;nschen, sollten Sie Ihren
    Internetbrowser
    so
    einstellen, dass er die Annahme von Cookies verweigert.</p>
  <p>Ein genereller Widerspruch gegen den Einsatz der zu
    Zwecken des Onlinemarketing eingesetzten Cookies kann bei einer Vielzahl der Dienste, vor allem im Fall des
    Trackings,
    über die US-amerikanische Seite <a href="http://www.aboutads.info/choices/" target="_blank" rel="noopener">http://www.aboutads.info/choices/</a>
    oder die EU-Seite <a href="http://www.youronlinechoices.com/" target="_blank" rel="noopener">http://www.youronlinechoices.com/</a>
    erklärt werden. Des Weiteren kann die Speicherung von Cookies mittels deren Abschaltung in den Einstellungen des
    Browsers erreicht werden. Bitte beachten Sie, dass dann gegebenenfalls nicht alle Funktionen dieses
    Onlineangebotes
    genutzt werden können.</p>
  <p>&nbsp;</p>
  <h2>Datenschutzerkl&auml;rung f&uuml;r SSL-/TLS-Verschl&uuml;sselung</h2>
  <p>
    Diese Website nutzt aus Gr&uuml;nden der Sicherheit und zum Schutz der &Uuml;bertragung vertraulicher Inhalte, wie
    zum
    Beispiel der Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-/TLS-Verschl&uuml;sselung. Eine verschl&uuml;sselte
    Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von &quot;http://&quot; auf &quot;https://&quot;
    wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.</p>
  <p>Wenn die SSL bzw. TLS Verschl&uuml;sselung aktiviert
    ist, k&ouml;nnen die Daten, die Sie an uns &uuml;bermitteln, nicht von Dritten mitgelesen werden.</p>
  <p>&nbsp;</p>
  <h2>
    Datenschutzerkl&auml;rung f&uuml;r Server-Log-Files</h2>
  <p>Der Provider dieser Website erhebt und speichert
    automatisch Informationen in so genannten Server-Log Files, die Ihr Browser automatisch an uns &uuml;bermittelt.
    Dies
    sind:</p>
  <ul>
    <li>Browsertyp und Browserversion</li>
    <li>verwendetes Betriebssystem</li>
    <li>Referrer URL</li>
    <li>Hostname des zugreifenden Rechners</li>
    <li>Uhrzeit der Serveranfrage</li>
  </ul>
  <p>Diese Daten sind nicht bestimmten Personen zuordenbar. Eine Zusammenf&uuml;hrung dieser Daten mit anderen
    Datenquellen wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachtr&auml;glich zupr&uuml;fen, wenn uns
    konkrete Anhaltspunkte f&uuml;r eine rechtswidrige Nutzung bekannt werden.</p>
  <p>&nbsp;</p>
  <h2>Datenschutzerkl&auml;rung
    für die Nutzung von Google Web Fonts</h2>
  <p>Diese Website nutzt zur einheitlichen Darstellung von Schriftarten so
    genannte Web Fonts, die von Google bereitgestellt werden. Beim Aufruf einer Seite l&auml;dt Ihr Browser die ben&ouml;tigten
    Web Fonts in ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen. Wenn Ihr Browser Web Fonts nicht
    unterst&uuml;tzt, wird eine Standardschrift von Ihrem Computer genutzt.</p>
  <p>Weitere Informationen zu Google Web
    Fonts finden Sie unter <a href="https://developers.google.com/fonts/faq" target="_blank" style="color: inherit;">https://developers.google.com/fonts/faq</a>
    und in der Datenschutzerkl&auml;rung von Google: <a href="https://www.google.com/policies/privacy/"
                                                        target="_blank"
                                                        style="color: inherit;">https://www.google.com/policies/privacy/</a>
  </p>
  <p>&nbsp;</p>
  <h2>&Auml;nderungen</h2>
  <p>Wir k&ouml;nnen diese Datenschutzerkl&auml;rung jederzeit ohne Vorank&uuml;ndigung
    anpassen. Es gilt die jeweils aktuelle, auf unserer Website publizierte Fassung. Soweit die Datenschutzerkl&auml;rung
    Teil einer Vereinbarung mit Ihnen ist, werden wir Sie im Falle einer Aktualisierung über die &Auml;nderung per
    E-Mail
    oder auf andere geeignete Weise informieren.</p>
  <p>&nbsp;</p>
  <h2>Fragen an den Datenschutzbeauftragten</h2>
  <p>Wenn
    Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an die f&uuml;r
    den
    Datenschutz zu Beginn der Datenschutzerkl&auml;rung aufgef&uuml;hrten, verantwortlichen Person in unserer
    Organisation.</p>
  <p>&nbsp;</p>
  <p>Basel, 26.02.2021
    <!--ACHTUNG: Wenn Sie die Quelle ohne Erlaubnis von SwissAnwalt entfernen, dann begehen Sie eine Urheberrechtsverletzung welche in jedem Fall unter Kostenfolge geahndet wird.-->
    <br/>Quelle: <a href="https://www.swissanwalt.ch" target="_blank" rel="noopener">SwissAnwalt</a>
  </p>
  <!--Bitte beachten Sie die AGB von SwissAnwalt betreffend allfällig anfallenden Kosten bei Weglassen der Quelle!-->

</app-legal>
