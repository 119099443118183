import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LandingPageComponent} from './landing-page/landing-page.component';
import {ImprintPageComponent} from './imprint-page/imprint-page.component';
import {DataPrivacyPageComponent} from './data-privacy-page/data-privacy-page.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { LegalComponent } from './legal/legal.component';
import { CookieComponent } from './cookie/cookie.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    ImprintPageComponent,
    DataPrivacyPageComponent,
    LegalComponent,
    CookieComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
